import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import ImageGallery from '../../components/PromoImageGallery/ImageGallery';

function MunirPostop() {
    const generalRef = useRef();
    const donorRef = useRef();
    const transplantedRef = useRef();
    const washingRef = useRef();
    const medicationRef = useRef();
    const complicationRef = useRef();


    const handleGeneral = () => generalRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleDonor = () => donorRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleTransplanted = () => transplantedRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleWashing = () => washingRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleMedication = () => medicationRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleComplication = () => complicationRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    return (
        <div className="row px-3 mt-5">
            <div className="col-md-3">
                <div className='d-sm-block d-md-none border mb-5 pb-5'>
                    <ImageGallery />
                </div>
                <div className="border d-none d-md-block" style={{'position':'sticky', 'top':100}}>
                    <ul className='border-bottom py-3' style={{'listStyle':'none'}}>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleGeneral}>
                                General Advice
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleDonor}>
                                Care Of Donor Area
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleTransplanted}>
                                Care Of Transplanted Area
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleWashing}>
                                Hair Washing Regimen
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleMedication}>
                                Medication Regimen
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleComplication}>
                                Complications That May Occur After Surgery
                            </span>
                        </li>
                    </ul>
                    <div className='mt-3'>
                        <ImageGallery />
                    </div>
                </div>
            </div>
            <div className="col-md-8">
            <div id="general">
                    <header>
                        <h3 className="text-center">POST OPERATIVE INSTRUCTIONS</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Munir/postOp.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body" ref={ generalRef }>
                                    <h5><u>General Advice</u></h5>
                                    <p>
                                        The time after surgery is a very important time to allow your body to heal and recover. This stage, especially the first 2 weeks, is crucial on the overall outcome of your surgery. Below are important advice and instructions that MUST be followed.
                                        <br/>
                                        IF you are unsure of any of this – please refer to the website or the contact details below to get in touch if you have any queries or concerns. 
                                    </p>
                                    <h6>FUE Head Post Op</h6>
                                    <ol>
                                        <li><span className="text-danger">DO NOT TOUCH</span> the recipient and donor areas until the day you start washing them [the 3rd day] (see below). </li>
                                        <li><span className="text-danger">DO NOT SCRATCH</span> the head at any time as it is important that germs are not transferred from your fingers / hands to the donor or recipient sites as this could cause infection. </li>
                                        <li><span className="text-danger">HEADBAND</span> - <span className="fw-bold">Keep the headband</span> on for 5 days [day and night, you can adjust the size if too tight]. Place icepacks after the operation over the headband for 10 minutes every half an hour for 2 days. These measures are to minimize the swelling from tracking down to the eyes/ face.</li>
                                        <li><span className="text-danger">FOOD</span> - <b>Eat a light meal</b> (ideally soft meals with minimal chewing) following the operation [for 2-3 days]. If you are given steroid medication for the next few days in order to avoid swelling of the eyes and forehead, it is imperative that you <b>Do not add salt</b> to your food over the next five days.</li>
                                        <li><span className="text-danger">SLEEP</span> - <b>Sleep on your back</b> but not on your stomach. Use a travel-pillow to sleep propped at a 45 degree angle for 2 weeks.</li>
                                        <li><span className="text-danger">ALCOHOL</span> – Consuming alcohol will affect the healing time of wounds after the hair transplant. It will be better to wait for 2 weeks post-op. <b>Avoid alcohol for a minimum of 48 hours post procedure.</b></li>
                                        <li><span className="text-danger">SMOKING</span> – Try to avoid smoking for at least 2 WEEKS POST SURGERY BUT NICOTINE REPLACEMENT THERAPY CAN BE USED STRAIGHT AFTER THE SURGERY (E.G. NICOTINE PATCHES, ETC.).</li>
                                        <li><span className="text-danger">HAIR PIECE/UNIT</span> - <b>If you are a hair piece wearer</b>: Do not put it again before at least 14 days after the operation.</li>
                                        <li><span className="text-danger">HAIRCUT</span> – <b>DO NOT</b> cut your hair for <b>6 weeks with scissors only on the transplanted area and can use clippers everywhere else. You can use clippers all over for a haircut after 3 months.</b>.</li>
                                        <li><span className="text-danger">EXERCISE</span> - <b>Avoid</b> strenuous exercise <b>for 14 days</b> following the operation.</li>
                                        <li><span className="text-danger">HEAD MOVEMENTS</span> - <b>Do not turn</b> your head in an upside down/ <b>Do not lean forwards</b> for the first 3 days after the operation.</li>
                                        <li><span className="text-danger">SUNLIGHT</span> - <b>Do not expose</b> your head to direct sunlight for <b>14 days</b> following the operation, if you go outside under direct sun, you will need to put a hat/cap. <b>Avoid sunburn</b> on the head for 2 months after the operation.</li>
                                        <li><span className="text-danger">SWIMMING</span> -  No swimming pool or any exposure to chlorinated water (e.g. hot tub, etc) for 2 months & No swimming in the sea or fresh water for <b>4 weeks.</b></li>
                                        <li><span className="text-danger">MEDICATION</span> – Can restart any medications that were stopped prior to the surgery. <b>DO NOT USE FINASTERIDE, MINOXIDIL OR IBUPROFEN / ANY ANTI-INFLAMMATORIES FOR UP TO 6 WEEKS POST SURGERY, DISCUSS THIS WITH THE MEDICAL TEAM PRIOR TO LEAVING ON THE DAY OF SURGERY.</b>.</li>
                                        <li><span className="text-danger">SPRAYING</span> - Spray every 15-20 minutes for the first 3 days.</li>
                                    </ol>
                                    <h6>FUE Beard Post Op</h6>
                                    <ol>
                                        <li><span className="text-danger">DO NOT TOUCH</span> the recipient and donor areas until the day you start washing them [the 3rd day] (see below). </li>
                                        <li><span className="text-danger">DO NOT SCRATCH</span> the face at any time as it is important that germs are not transferred from your fingers / hands to the donor or recipient sites as this could cause infection. </li>
                                        <li><span className="text-danger">FOOD</span> - <b>Eat a light meal</b> (ideally soft meals with minimal chewing) following the operation [for 2-3 days]. If you are given steroid medication for the next few days in order to avoid swelling of the eyes and forehead, it is imperative that you <b>Do not add salt</b> to your food over the next five days.</li>
                                        <li><span className="text-danger">SLEEP</span> - <b>Sleep on your back</b> but not on your stomach. Use a travel-pillow to sleep propped at a 45 degree angle for 2 weeks.</li>
                                        <li><span className="text-danger">ALCOHOL</span> – Consuming alcohol will affect the healing time of wounds after the hair transplant. It will be better to wait for 2 weeks post-op. <b>Avoid alcohol for a minimum of 48 hours post procedure.</b></li>
                                        <li><span className="text-danger">SMOKING</span> – Try to avoid smoking for at least 2 WEEKS POST SURGERY BUT NICOTINE REPLACEMENT THERAPY CAN BE USED IMMEDIATELY AFTER THE SURGERY (E.G. NICOTINE PATCHES, ETC.).</li>
                                        <li><span className="text-danger">TRIMMING</span> – <b>DO NOT</b> cut your hair ON THE TRANSPLANTED AREA UNTIL AT LEAST <b>6 WEEKS POST SURGERY, scissors only on the transplanted area. You can use clippers all over after 3 months.</b>.</li>
                                        <li><span className="text-danger">EXERCISE</span> - <b>Avoid</b> strenuous exercise <b>for 14 days</b> following the operation.</li>
                                        <li><span className="text-danger">HEAD MOVEMENTS</span> - <b>Do not turn</b> your head in an upside down/ <b>Do not lean forwards</b> for the first 3 days after the operation.</li>
                                        <li><span className="text-danger">SUNLIGHT</span> - <b>Do not expose</b> your head to direct sunlight for <b>14 days</b> following the operation, if you go outside under direct sun, you will need to put a hat/cap. <b>Avoid sunburn</b> on the head for 2 months after the operation.</li>
                                        <li><span className="text-danger">SWIMMING</span> -  No swimming pool or any exposure to chlorinated water (e.g. hot tub, etc) for 2 months & No swimming in the sea or fresh water for <b>4 weeks.</b></li>
                                        <li><span className="text-danger">MEDICATION</span> – Can restart any medications that were stopped prior to the surgery. <b>DO NOT USE FINASTERIDE, MINOXIDIL OR IBUPROFEN / ANY ANTI-INFLAMMATORIES FOR UP TO 6 WEEKS POST SURGERY, DISCUSS THIS WITH THE MEDICAL TEAM PRIOR TO LEAVING ON THE DAY OF SURGERY.</b>.</li>
                                        <li><span className="text-danger">SPRAYING</span> - Spray every 15-20 minutes for the first 3 days.</li>
                                    </ol>
                                    <h6>FUE Eyebrow Post Op</h6>
                                    <ol>
                                        <li><span className="text-danger">DO NOT TOUCH</span> the recipient and donor areas until the day you start washing them [the 3rd day] (see below). </li>
                                        <li><span className="text-danger">DO NOT SCRATCH</span> the eyebrow region at any time as it is important that germs are not transferred from your fingers / hands to the donor or recipient sites as this could cause infection. </li>
                                        <li><span className="text-danger">FOOD</span> - <b>Eat a light meal</b> (ideally soft meals with minimal chewing) following the operation [for 2-3 days]. If you are given steroid medication for the next few days in order to avoid swelling of the eyes and forehead, it is imperative that you <b>Do not add salt</b> to your food over the next five days.</li>
                                        <li><span className="text-danger">SLEEP</span> - <b>Sleep on your back</b> but not on your stomach. Use a travel-pillow to sleep propped at a 45 degree angle for 2 weeks.</li>
                                        <li><span className="text-danger">ALCOHOL</span> – Consuming alcohol will affect the healing time of wounds after the hair transplant. It will be better to wait for 2 weeks post-op. <b>Avoid alcohol for a minimum of 48 hours post procedure.</b></li>
                                        <li><span className="text-danger">SMOKING</span> – Try to avoid smoking for at least 2 WEEKS POST SURGERY BUT NICOTINE REPLACEMENT THERAPY CAN BE USED STRAIGHT AFTER THE SURGERY (E.G. NICOTINE PATCHES, ETC.).</li>
                                        <li><span className="text-danger">TRIMMING</span> – <b>DO NOT</b> cut your hair ON THE TRANSPLANTED AREA UNTIL AT LEAST <b>6 WEEKS POST SURGERY, scissors only on the transplanted area. You can use clippers all over after 3 months.</b>.</li>
                                        <li><span className="text-danger">EXERCISE</span> - <b>Avoid</b> strenuous exercise <b>for 14 days</b> after the operation.</li>
                                        <li><span className="text-danger">HEAD MOVEMENTS</span> - <b>Do not turn</b> your head in an upside down/ <b>Do not lean forwards</b> for the first 3 days after the operation.</li>
                                        <li><span className="text-danger">SUNLIGHT</span> - <b>Do not expose</b> your head to direct sunlight for <b>14 days</b> after the operation, if you go outside under direct sun, you will need to put a hat/cap. <b>Avoid sunburn</b> on the head for 2 months after the operation.</li>
                                        <li><span className="text-danger">SWIMMING</span> -  No swimming pool or any exposure to chlorinated water (e.g. hot tub, etc) for 2 months & No swimming in the sea or fresh water for <b>4 weeks.</b></li>
                                        <li><span className="text-danger">MEDICATION</span> – Can restart any medications that were stopped prior to the surgery. <b>DO NOT USE FINASTERIDE, MINOXIDIL OR IBUPROFEN / ANY ANTI-INFLAMMATORIES FOR UP TO 6 WEEKS POST SURGERY, DISCUSS THIS WITH THE MEDICAL TEAM PRIOR TO LEAVING ON THE DAY OF SURGERY.</b>.</li>
                                        <li><span className="text-danger">SPRAYING</span> - Spray every 15-20 minutes for the first 3 days.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="washing">
                    <header>
                        <h3 className="text-center">CARE OF TRANSPLANTED AND DONOR AREA / WASHING REGIME</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://s3.eu-central-1.wasabisys.com/patient-portal/videos/dr_masood_munir/munir_washing_vid' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body">
                                    <h5 ref={ donorRef }><u>Care Of Donor Area</u></h5>
                                    <p>
                                        The following evening after surgery (24 hours at least), remove your headband and gently soak the bandage at the back of your head with warm water in order to ensure it doesn’t stick to skin as you gently peel it away.
                                        If at any time there is bleeding in either area, <b>do not be concerned</b>. Apply direct pressure with a dry, clean cloth until the bleeding stops. This may take several minutes If you have any concerns or questions, please contact us. Once this is completed reapply your headband.  
                                        If you experience itching or dryness to the donor area – you can exfoliate/scrub the area after 10 days using aloe vera gel or coconut oil. 
                                    </p>

                                    <h5 className="mt-3" ref={transplantedRef}><u>Care Of Transplanted Area</u></h5>
                                    <p>
                                        Crusts will reform after the procedure. It is important to take care of the transplanted area after the surgery. The scabs should come off in 2 weeks. 
                                        <br/>
                                        Appropriate care of the recipient area will minimize crusting and make the transplant less noticeable and the healing more rapid.
                                        <br/><br/>
                                        <b><u>SPRAYING</u></b>
                                        <br/>
                                        A spray bottle containing saline water solution is supplied on discharge. You may use mineral/bottled water when the sterile water provided is finished.  
                                        <br/>
                                        FIRST 3 DAYS - Spray the transplanted area <span className="text-danger">EVERY 15-20 MINUTES during your waking hours</span>, Put some towels around your shoulders while using the Spray and let the extra water drip down.  
                                        <br/>
                                        <span className="text-danger">DO NOT TOUCH the transplanted hair.</span>  
                                    </p>

                                    <h5 className="mt-3" ref={ washingRef }><u>Hair Washing Regimen</u></h5>
                                    <p>
                                        <b className="text-danger">FOR FOUR WEEKS FOR FACE + SCALP WASHING, ONLY USE BABY SHAMPOO.</b> <br/>
                                        <b>WASHING: THE FIRST WASH WILL BE ON DAY 3, </b><span>e.g. if surgery is on Monday, the first wash will be on Thursday evening.</span>
                                        <br/>
                                        Continue to follow the hair washing regime as instructed in the table below. 
                                        <br/>
                                        <b>IMPORTANT:  No chemical-based products such as hair colour, hair lacquer or sprays etc should be used on the head for 6 weeks following the operation. Shampooing or rinsing the transplanted area must be gentle for the first 10 days. So, DO NOT RUB, PICK, Or SCRATCH, as this may dislodge grafts</b>
                                    </p>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Instructions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0-2</td>
                                                    <td>
                                                        Day of Procedure (Day 0), Day 1 & Day 2 = NO WASHING. CONTINUE SPRAYING.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        <h6>FUE Head Post Op</h6>
                                                        <b className="text-danger">FIRST WASH</b> - Wash the scalp by spraying the whole head with the Half water/ Half Baby shampoo mixture. Leave it on for 45-60 minutes and keep on topping up the mixture on the whole of the head.
                                                        <br/>
                                                        <b>Do not touch</b> the transplanted area whilst you can touch and rub the rest of your head with your finger tips. Rinse off using a cup or jug of water, by pouring it BEHIND the transplanted area & letting it run down onto the grafted area. DO NOT USE A SHOWER HEAD. 
                                                        <br/><br/>
                                                        LET THE SCALP DRY ITSELF. DO NOT USE A HAIRDRYER. 
                                                        <h6 className='mt-3'>FUE Beard Post Op</h6>
                                                        <b className="text-danger">FIRST WASH</b> - Wash the face by spraying the whole area with the Half water/ Half Baby shampoo mixture. Leave it on for 45-60 minutes and keep on topping up the mixture on the whole of the area.
                                                        <br/>
                                                        <b>Do not touch</b> the transplanted area whilst you can touch and rub the rest of your head with your finger tips. Rinse off using a cup or jug of water, by pouring it BEHIND the transplanted area & letting it run down onto the grafted area. DO NOT USE A SHOWER HEAD. 
                                                        <br/><br/>
                                                        LET THE FACE DRY ITSELF. DO NOT USE A HAIRDRYER.
                                                        <h6 className='mt-3'>FUE Eyebrow Post Op</h6>
                                                        <b className="text-danger">FIRST WASH</b> - Wash the eyebrow by spraying the whole area with the Half water/ Half Baby shampoo mixture. Leave it on for 45-60 minutes and keep on topping up the mixture.
                                                        <br/>
                                                        <b>Do not touch</b> the transplanted area whilst you can touch and rub the rest of your head with your finger tips. Rinse off using a cup or jug of water, by pouring it BEHIND the transplanted area & letting it run down onto the grafted area. DO NOT USE A SHOWER HEAD. 
                                                        <br/><br/>
                                                        LET THE EYEBROW DRY ITSELF. DO NOT USE A HAIRDRYER.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>
                                                        No washing
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5-13</td>
                                                    <td>
                                                        <h6>FUE Head Post Op</h6>
                                                        <b className="text-danger">CONTINUE WASH</b> - Wash the scalp by initially spraying the whole head with the Half water / Half Baby shampoo mixture to wet the scalp. THE REST OF THE WASH WILL BE WITH NEAT SHAMPOO. <br/>
                                                        Top up every the shampoo lather on the transplanted area every 5 minutes with baby shampoo you lather in your hand with only a small ammount of water so it creates a more concentrated shampoo lather and you scoop this up and tap it on with your finger tips, GENTLY.
                                                        Continue this process for at least 45-60 minutes before you wash off the shampoo mixture with a glass / jug of water.
                                                        <br/>
                                                        RUB THE DONOR AREA FIRMLY - You need to exfoliate the donor area by vigorously scrubbing neat baby shampoo onto the donor area several times over the hour long wash. This is to reduce your risk of a post surgery skin infection so if you get spots even with this technique please apply a antimicrobial cream like Savalon or Sudocrem several times a day. Apply this process to the transplanted area 4 WEEKS post-surgery.  
                                                        <br/>
                                                        Rinse off using a cup or jug of water, by pouring it BEHIND the transplanted area & letting it run down onto the grafted area. 
                                                        <br/>
                                                        DO NOT USE A SHOWER HEAD 
                                                        <br/><br/>
                                                        LET THE SCALP DRY ITSELF. DO NOT USE A HAIRDRYER. 

                                                        <h6 className='mt-3'>FUE Beard Post Op</h6>
                                                        <b className="text-danger">CONTINUE WASH</b> - Wash the scalp by initially spraying the whole head with the Half water / Half Baby shampoo mixture to wet the scalp. THE REST OF THE WASH WILL BE WITH NEAT SHAMPOO. <br/>
                                                        Top up every the shampoo lather on the transplanted area every 5 minutes with baby shampoo you lather in your hand with only a small ammount of water so it creates a more concentrated shampoo lather and you scoop this up and tap it on with your finger tips, GENTLY.
                                                        Continue this process for at least 45-60 minutes before you wash off the shampoo mixture with a glass / jug of water.
                                                        <br/>
                                                        RUB THE DONOR AREA FIRMLY - You need to exfoliate the donor area by vigorously scrubbing neat baby shampoo on to the donor area several times over the hour long wash. This is to reduce your risk of a post surgery skin infection so if you get spots even with this technique please apply a antimicrobial cream like Savalon or Sudocrem several times a day. Apply this process to the transplanted area 4 WEEKS post-surgery.  
                                                        <br/>
                                                        Rinse off using a cup or jug of water, by pouring it BEHIND the transplanted area & letting it run down onto the grafted area. 
                                                        <br/>
                                                        DO NOT USE A SHOWER HEAD 
                                                        <br/><br/>
                                                        LET THE FACE DRY ITSELF. DO NOT USE A HAIRDRYER. 

                                                        <h6 className='mt-3'>FUE Eyebrow Post Op</h6>
                                                        <b className="text-danger">CONTINUE WASH</b> - Wash the scalp by initially spraying the whole head with the Half water / Half Baby shampoo mixture to wet the scalp. THE REST OF THE WASH WILL BE WITH NEAT SHAMPOO. <br/>
                                                        Top up every the shampoo lather on the transplanted area every 5 minutes with baby shampoo you lather in your hand with only a small ammount of water so it creates a more concentrated shampoo lather and you scoop this up and tap it on with your finger tips, GENTLY.
                                                        Continue this process for at least 45-60 minutes before you wash off the shampoo mixture with a glass / jug of water.
                                                        <br/>
                                                        RUB THE DONOR AREA FIRMLY - You need to exfoliate the donor area by vigorously scrubbing neat baby shampoo oto the donor area several times over the hour long wash. This is to reduce your risk of a post surgery skin infection so if you get spots even with this technique please apply a antimicrobial cream like Savalon or Sudocrem several times a day. Apply this process to the transplanted area 4 WEEKS post surgery.  
                                                        <br/>
                                                        Rinse off using a cup or jug of water, by pouring it BEHIND the transplanted area & letting it run down onto the grafted area. 
                                                        <br/>
                                                        DO NOT USE A SHOWER HEAD 
                                                        <br/><br/>
                                                        LET THE AREA DRY ITSELF. DO NOT USE A HAIRDRYER. 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td>
                                                        Apply the shampoo on the transplanted area gently with your finger tips rub gently for 1 hour to remove any remaining crusts.
                                                        <br/>
                                                        Top up repeatedly. Rinse off with a cup or jug. <b>Dry skin crusts/scabs should fall off by day 14. PLEASE SPEAK TO THE MEDICAL TEAM IF CRUSTS ARE RESISTENT AND NOT COMING OF AFTER THIS WASH.</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Onwards</td>
                                                    <td>
                                                        <b>After 2 weeks</b> – return back to <span className="text-danger">normal showering BUT STILL USING BABY SHAMPOO FOR A FURTHER 2 WEEKS, gently rubbing the scalp (Ideally do not Wash Hair more than 2-3 times per week)</span>. <br/>
                                                        <b>After 4 weeks</b> wash your whole head with whichever shampoo you want and ensure you are vigorously scrubbing the donor and transplanted area vigorously when washing up to 2-3 months post surgery to reduce risk of ingrowing hairs, blocked follicles, etc.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="medicine">
                    <header>
                        <h3 className="text-center">MEDICATION REGIMEN</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Munir/medication.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body" ref={ medicationRef }>
                                    <p>
                                        The table below outlines the Medication Regime which starts from the Day of Surgery (Day 0). It is important that you follow this regime in order to reduce the risk of a possible infection and to help with the healing process.
                                        <br/><br/>
                                        <b>Doxycycline</b> – Is an antibiotic given to reduce the risk of having an infection
                                        
                                        {/* <br/>
                                        <b>Lansoprazole</b> – Is an Anti-Acid medication to protect the stomach from Prednisolone 
                                        <br/>
                                        <b>Prednisolone</b> – Is a Steroid/Anti-inflammatory medication given to reduce the amount of swelling post-procedure that may occur to the forehead and facial region. It is important that once started <b>you do not suddenly stop</b> it and must be weaned down slowly [any concerns, please contact your GP or seek medical advice]
                                        */}
                                        <br/><br/> 
                                        We provide you with all of the medication listed in the table below on discharge. IF you have any allergies to these medications –please inform us so changes can be made accordingly. 
                                    </p>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>PM</th>
                                                    {/* <th>AM</th>
                                                    <th>AM</th> */}
                                                </tr>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>After Dinner</th>
                                                    {/* <th>30 minutes Before Breakfast</th>
                                                    <th>After Breakfast</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0 <br/>(Day of Operation)</td>
                                                    <td>2 X Doxycycline</td>
                                                    {/* <td>0</td>
                                                    <td>0</td> */}
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>1 X Doxycycline</td>
                                                    {/* <td>1 X Lansoprazole</td>
                                                    <td>3 X Prednisolone</td> */}
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>1 X Doxycycline</td>
                                                    {/* <td>1 X Lansoprazole</td>
                                                    <td>2 X Prednisolone</td> */}
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>1 X Doxycycline</td>
                                                    {/* <td>1 X Lansoprazole</td>
                                                    <td>2 X Prednisolone</td> */}
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>1 X Doxycycline</td>
                                                    {/* <td>1 X Lansoprazole</td>
                                                    <td>1 X Prednisolone</td> */}
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>1 X Doxycycline</td>
                                                    {/* <td>1 X Lansoprazole</td>
                                                    <td>1 X Prednisolone</td> */}
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>1 X Doxycycline</td>
                                                    {/* <td>1 X Lansoprazole</td>
                                                    <td>1 X Prednisolone</td> */}
                                                </tr>
                                                <tr>
                                                    <td>Package Leaflet</td>
                                                    <td><a href="https://patient-portal.s3.fr-par.scw.cloud/website/medicationInstructions/Doxycycline.pdf" target="blank">Doxycycline Leaflet</a></td>
                                                    {/* <td><a href="https://patient-portal.s3.fr-par.scw.cloud/website/medicationInstructions/Lansoprazole.pdf" target="blank">Lansoprazole Leaflet</a></td>
                                                    <td><a href="https://patient-portal.s3.fr-par.scw.cloud/website/medicationInstructions/Prednisolone.pdf" target="blank">Prednisolone Leaflet</a></td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <ul>
                                        Other Medications
                                        <li><b>PAIN AND INFORMATION - If you experience pain,</b> take 2 tablets of paracetamol every 4 - 6 hours (max 8 in 24 hours) for the next 2 - 3 days. After this take it as pain allows. IF THE PAIN PERSISTS, CONTACT US OR CONSULT WITH A MEDICAL PROFESSIONAL.</li>
                                        <li><b>ITCHING - Antihistamine medication - If you experience itching in the transplanted or donor area and it becomes intolerable,</b> an antihistamine can be used. In such cases you can take Piriton 4mg three times a day or Cetirizine 10mg once daily (which can cause drowsiness) or Loratidine 10mg once daily. All can be purchased as over the counter medications.</li>
                                    </ul>
                                    <div className="text-center w-100 fw-bold small">(*PLEASE CONSULT YOUR GP BEFORE TAKING ANY MEDICATION*) </div>
                                    <div className="mt-5">
                                        <h5>OTHER HAIR-RELATED TREATMENTS/REGIMES</h5>
                                        <ul>
                                            <li>
                                                <span className="text-danger">Vitamins (Hair-Vit) or Biotin Tablets</span> - Recommended as a dietary supplement to help strengthen hair. You can purchase these over the counter or from online websites such as Amazon. Can start these immediately after the surgery. Take for 12 months.
                                            </li>
                                            <li>
                                                <span className="text-danger">Caffeinated Shampoos (Alpecin)</span> - These can be used after 4 weeks following the procedure. it helps to stimulate and energise the scalp in order to help encourage hair growth, while restoring hair follicles. RUB VIGOROUSLY WHEN WASHING AFTER 4 WEEKS.
                                            </li>
                                            <li>
                                                <span className="text-danger">Scalp Micropigmentation (SMP) / Dermaroller Use</span> - Wait 6 months post procedure before resuming these.
                                            </li>
                                            <li>
                                                <span className="text-danger">Hydration Of Scalp</span> - Use Coconut Oil 3 - 4 times daily. Rub onto donor area from day 2. <b>DAB</b> onto transplanted area from day 10. RUB ALL OVER HEAD FROM DAY 14.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                <div id="complication" ref={complicationRef}>
                    <header>
                        <h3 className="text-center">WHAT TO EXPECT FOLLOWING FUE SURGERY</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <div className="card-body">
                                    <ul>
                                        <li><span className="text-danger">HAIR SHEDDING</span>: is expected for the first 8-12 weeks after the operation. It is a normal process and should not be a concern. The healthy hair follicles are firmly in place at 10 days after the operation and cannot be dislodged. <b>It is normal for the hair, bulb and associated crust to be shed and this does not represent a lost graft. If a graft is lost (something that may occur the first few days following the operation) there will be bleeding at the site of the lost graft. Therefore, if you don’t see any bleeding, don’t be concerned.</b></li>
                                        <li><span className="text-danger">SWELLING</span>: this is quite rare but could occur usually 24 to 48 hours after the surgery. You may notice some swelling in the forehead, which may travel down towards the eyebrows. This will resolve itself, without intervention, but can take 10 - 14 days after it begins. Raising the top of the bed will help.</li>
                                        <li><span className="text-danger">BLEEDING</span>: there may be some bleeding directly after the surgery from the donor area, if any bleeding occurs, it is important to apply pressure with some gauze for as long as it takes to settle.</li>
                                        <li><span className="text-danger">PAIN</span>: sometimes pain can occur in the donor, the transplanted area or your neck area due to the procedure/ the position, then <b>Paracetomol</b> is recommended for this. </li>
                                        <li><span className="text-danger">NUMBNESS/TINGLING SENSATION</span> in the recipient area sometimes occurs, this will resolve without intervention. In the majority this lasts for a few weeks to few months. In rare occasions it can be permanent. </li>
                                        <li><span className="text-danger">INFECTION</span>: There is a very low risk of infection as with any surgery. You should return to the clinic if you feel there is any signs of infection such as localised redness, pain…etc. This is used for preventing possible infections and ensuring a smooth healing progress. </li>
                                        <li><span className="text-danger">PINKISH SCALP</span> – This can last up to a few months post procedure. This is part of the normal healing process.  </li>
                                        <li><span className="text-danger">DRY ITCHY SCALP</span> – Use Coconut Oil or Aloe Vera Gel after 2 weeks to moisturise the scalp if dry or itchy.  </li>
                                    </ul>
                                    <h5 className="mt-5"><u>HAIR GROWTH</u></h5>
                                    <p>
                                        From the day of the procedure, it can 2-3 months before your new follicles fully take and begin to naturally grow new hair.
                                        <br/>
                                        Your new hair growth may be rough and uneven at first, but this will then resolve and the hair will appear normal.
                                        <br/>
                                        The final result is expected at approximately 12-18 months. Each patients results and recovery time differs. Estimated timelines are shown below in the diagram: 
                                    </p>
                                    <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/manpostopTimeline.png" alt="Timeline" className="img-fluid w-100" />
                                    <ReactPlayer url='https://s3.eu-central-1.wasabisys.com/patient-portal/videos/dr_masood_munir/growth.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                    <p>SHOULD YOU HAVE ANY CONCERNS, QUERIES AND ENQUIRIES PLEASE NOTE THAT YOU CAN CONTACT OUR PATIENT LIAISON MANAGER</p>
                                    <p>In the case of any emergency, the patient is advised to have a low thresold for self referal to A&E. This is the case for both in hours and out of hours.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MunirPostop
